<template>
  <div class="flex flex-col mb-10">

    <div style="margin-left: -10px; padding:30px; border: 1px solid #8080807a; border-radius: 25px;; border-radius: 5px;">

      <div class="md-layout">
        <div class="md-layout-item md-size-50">
        <label class="pr-4 text-right text-uppercase font-bold required"> Course Type</label>
        <RadioButtonComponent
          labelClass="font-bold"
          :label-outline="false"
          :block="false"
          :items="courseType"
          v-model.trim="$v.form.course_type.$model"
          :message="!$v.form.course_type.required && $v.form.course_type.$dirty ? 'Field is required' : null"
          />
        </div>
      </div>
      <div class="md-layout mt-4">
      <div class="md-layout-item md-size-50">
        <InputFieldComponent
            label="Online Course Link"
            label-class="text-sm"
            v-model.trim="form.online_link"
            
        />
      </div>
    </div>
    </div>

    

    <div class="flex justify-end mt-4">
      <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button>
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Next"
          type="button"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import {
  Button,
  RadioButtonComponent,
  InputFieldComponent
} from "@/components";

import { required } from "vuelidate/lib/validators";
import cms from "@/data/cms";

export default {
  components: {
    Button,
    RadioButtonComponent,
    InputFieldComponent
  },

  data() {
    return {
      active: false,
      loading: false,
      radio: false,
      courseType: cms.courseType,
      
      form: {
           course_type: '',
           online_link: '',
           is_branch: false,
           is_online: false,
      }
      
    }
  },

  methods: {

    submit() {

      this.$v.$touch();
      if(this.$v.$invalid) return;

      if(this.form.course_type=='BOTH'){
        this.form.is_branch = true;
        this.form.is_online = true;
      }
      if(this.form.course_type=='BRANCH'){
        this.form.is_branch = true;
        this.form.is_online = false;
      }
      if(this.form.course_type=='ONLINE'){
        this.form.is_branch = false;
        this.form.is_online = true;
      }

      let formData = this.getLocal('package-create-info');
      formData = {
        ...formData,
        ...this.form
      };
      this.setLocal('package-create-info', formData);
      this.$emit("menu", this.$route.name);
      this.$router.push({ name: 'packageCreation.adons'});
    },

    clearAll() {
      this.form = {
        course_type: '',
        online_link: '',
        is_branch: false,
        is_online: false,
      }
    
     let formData = this.getLocal('package-create-info');
      
      formData = {
        ...formData,
        ...this.form
      };

      this.$emit("menu", this.$route.name+'#false');
      this.setLocal('package-create-info', formData);
    }
  },

  created() {
     
     let existData = this.getLocal('package-create-info');
     if (existData && existData.course_type) this.form = existData;
    
  },

  validations: {
    form: {
      course_type: {required}
    }
  }
}
</script>